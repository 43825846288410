// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/login.js\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/login.js");
}
// REMIX HMR END

import React from "react";
import { Form as RemixForm, useLoaderData, useActionData, Link, useNavigation } from "@remix-run/react";
import { getSession, commitSession, destroySession } from "../sessions.js";
import { json, redirect } from "@remix-run/cloudflare";
import { Col, Button, Row, Container, Card, Form, InputGroup } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import styles from "../assets/styles/style.css";
import remixicons from "../assets/styles/remixicon.css";
import { createClient } from '@supabase/supabase-js';
import Loading from "../components/Loading";
import logger from "~/lib/logger";
import { verify_password } from "~/lib/crypto";
import PasswordField from "../components/PasswordField.js";
export const links = () => [{
  rel: "stylesheet",
  href: styles
}, {
  rel: "stylesheet",
  href: remixicons
}];
export async function action({
  request,
  context
}) {
  console.log("in action of login.js");
  await logger(context.env, {
    event: "attempted login",
    email: request.headers.get("email")
  });
  console.log("after logger in login.js");
  const supabase = createClient(context.env.SUPABASE_URL, context.env.SUPABASE_KEY);
  const formData = await request.formData();
  const {
    data,
    error
  } = await supabase.from("user").select("*, role (id, name, role_scope (scope))").eq('email', formData.get("email")).is("date_removed", null);
  console.log("user login data:", JSON.stringify(data, null, 4));
  if (error) {
    console.log("user login error:", error);
    //return json({ login_success: false });
  }
  if (data && data.length > 0) {
    const user = data[0];
    if (await verify_password(user.password, formData.get("password"))) {
      const scopes = user.role.map(r => r.role_scope.map(rs => rs.scope)).flat();
      console.log("determined scopes: ", scopes);
      const session = await getSession(request.headers.get("Cookie"));
      session.set("email", formData.get("email"));
      session.set("user", {
        ...user,
        role: undefined,
        scopes,
        password: undefined
      });
      return redirect("/app", {
        headers: {
          "Set-Cookie": await commitSession(session)
        }
      });
    }
  }

  //const { data, error } = await supabase.auth.signInWithPassword({ email: formData.get("email"), password: formData.get("password") });
  //if (data && data.user && data.user) {

  //	const session = await getSession(request.headers.get("Cookie"));
  //	session.set("email", formData.get("email"));
  //	return redirect("/app", {
  //		headers: {
  //			"Set-Cookie": await commitSession(session),
  //		},
  //	});
  //}
  return json({
    login_success: false
  });
}
export async function loader({
  request
}) {
  console.log("in LOADER of login.js");
  const session = await getSession(request.headers.get("Cookie"));
  const email = session.get("email");
  if (email) {
    console.log("in loader of login.js and about to redirect to / because we are logged in (" + email + ")");
    return redirect("/app", 301);
  }
  //console.log("apparently not logged in in the loader of login.js");
  return json({
    "status": "not logged in"
  });
}
export default function LoginPage() {
  _s();
  const navigation = useNavigation();

  //const loader_data = useLoaderData();
  const action_data = useActionData();
  //Loader-data:[[{JSON.stringify(loader_data, null, 4)}]]<br/>
  return <>	
			{navigation.state === "submitting" && <Loading />}		
			<Container>
				<Row className="vh-100 d-flex justify-content-center align-items-center">
					<Col md={8} lg={6} xs={12}>
						<div style={{
            textAlign: "center"
          }}><img style={{
              maxWidth: "200px"
            }} src={logo} alt="logo" /></div>
						<h1 style={{
            padding: "10px 0px 10px 0px",
            textAlign: "center"
          }} className="fw-bold mb-2">Portal</h1>
						<Card className="shadow">
							<Card.Body>
								<div className="mb-3 mt-md-4">
									<div className="mb-3">
										<RemixForm method="post">
											<Form.Group className="mb-3">
												<Form.Label className="text-center">Email address</Form.Label>
												<Form.Control id="email" name="email" type="email" placeholder="Enter email" />
											</Form.Group>
											<Form.Group className="mb-3">
												<Form.Label>Password</Form.Label>
												<PasswordField autoComplete="on" />
											</Form.Group>
											<div className="d-grid">
												<Button variant="primary" type="submit">Login</Button>
											</div>
										</RemixForm>
									</div>
								</div>
							</Card.Body>
							{action_data && action_data.login_success === false && <Card.Footer>
									<b>Login unsuccessful</b>
								</Card.Footer>}
							
							<Card.Footer>
								<div className="d-flex justify-content-center links">
									<Link to="/forgot">Forgot password?</Link>
								</div>
							</Card.Footer>
						</Card>
					</Col>
				</Row>
			</Container>
		</>;
}
_s(LoginPage, "aukQXiTB1YNvBVcfpiY636rccjM=", false, function () {
  return [useNavigation, useActionData];
});
_c = LoginPage;
var _c;
$RefreshReg$(_c, "LoginPage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;